import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ApiService } from '../../../api.service';
import { FormBuilder, FormGroup } from  '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sm-file-multiple',
  templateUrl: './sm-file-multiple.component.html',
  styleUrls: ['./sm-file-multiple.component.scss']
})
export class SmFileMultipleComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() disabled: any = false;
	uploadResponse:any = {}
	files:any = []
	reorderModel:any = []
	subs = new Subscription();

  constructor(public api: ApiService, private formBuilder: FormBuilder, private dragulaService: DragulaService) {

	}

  ngOnInit() {
		this.subs.add(this.dragulaService.drop()
		.subscribe(({ name, el, target, source, sibling }) => {
			let newOrder = [];
			let inputs = document.querySelectorAll(`#orderForm_${this.field} input[type=hidden]`);
			inputs.forEach((item:any)=>{
				newOrder.push(this.files.find(e => e.id == item.value));
			});
			this.files = newOrder;
			this.syncData();
		})
	);
	}
	
	ngOnChanges(changes: SimpleChanges) {
		this.initData();
	}

	initData() {
		this.files = [];
		if (this.model[this.field]) {
			let splitted = this.model[this.field].split(",");
			for (let file of splitted) {
				this.files.push({id: Math.round(Math.random()*999999999999), name: file, uploadResponse:{}});
			}
		}
	}

	fileSelected($event) {
		if (!$event.target.files || !$event.target.files[0]) {
			return;
		}
		for (let file of $event.target.files) {
			this.uploadOneFile(file);
		}
		setTimeout(()=>{
			$event.target.value = null;
		}, 400);
	}

	uploadOneFile(file) {
		let form = this.formBuilder.group({
      file: file
		});
		const formData = new FormData();
		formData.append('file', form.get('file').value);
		let newFile:any = {id: Math.round(Math.random()*999999999999), name: "", uploadResponse:{}};
		this.files.push(newFile);

		this.api.upload(formData).subscribe(
			(res) => {				
				newFile.uploadResponse = res;
				if (newFile.uploadResponse.status == "done") {
					newFile.name = newFile.uploadResponse.slug;
					newFile.uploadResponse = {};
					this.syncData();
				}
			},
			(err) => {
				console.log('UPLOAD ERROR', err);
			}
		);
	}

	delete(index) {
		this.files.splice(index, 1);
		this.syncData();
		return false;
	}

	syncData() {
		this.model[this.field] = this.files.map(item => item.name).join(",");
		//console.log('sync', this.model[this.field]);
	}

}