import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbdModalContent } from './modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [NgbdModalContent],
	entryComponents: [NgbdModalContent],
  imports: [
		FormsModule,
		TranslateModule,
    CommonModule,
	],
	exports: [NgbdModalContent]
})
export class ModalModule {
	getModal() {
		return NgbdModalContent;
	}
}
