import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sm-datetime',
  templateUrl: './sm-datetime.component.html',
  styleUrls: ['./sm-datetime.component.scss']
})
export class SmDatetimeComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() value:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() disabled: any = false;
	dateContainer:any = {};
	time:any = {};

  constructor() { 
		//this.initDate();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.value) {
			this.initDate();
		}
	}

	initDate() {
		let date = new Date();
		if (this.value) {
			date = new Date(this.value);
		}
		this.dateContainer = {year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()};
		this.time = {
			hour: date.getHours(), minute: date.getMinutes()
		}
	}

  ngOnInit() {
		this.initDate();
	}
	
	setNewDate() {
		function addZeros(number) { 
			return (number < 10 ? '0' : '') + number;
		}
		this.model[this.field] = this.dateContainer.year+"-"+addZeros(this.dateContainer.month)+"-"+addZeros(this.dateContainer.day)+" "+
			addZeros(this.time.hour)+":"+addZeros(this.time.minute)+":00";
	}

}

