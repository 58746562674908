import { ElementRef, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders } from  '@angular/common/http';
import { Router } from '@angular/router';
import { ModalModule } from './modal/modal.module';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { map } from  'rxjs/operators';
import { environment } from '../environments/environment';
import * as moment from 'moment';
import 'moment/locale/de-ch';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
	public apiUrl = environment.aipUrl;
	public fileUrl = environment.fileUrl;
	public thumbUrl = environment.thumbUrl;	
	public production = environment.production;	
	public settings:any = {
	};
	defaultOptions:any = {}
	public user:any = false

  constructor(public HttpClient:HttpClient, public router: Router, private mm:ModalModule, public modalService: NgbModal, private translateService:TranslateService) { 
		this.loadSettings();
		this.getCurrentUser();
	}

	saveSettings = function () {
		localStorage.setItem('data_recipe', JSON.stringify(this.settings));
	}

	async getCurrentUser() {
		if (localStorage.isLoggedin == "true" && this.settings.loginData) {
			let resp:any = await this.request("user/currentUser", {});
			if (resp.ok) {
				this.user = resp.user;
				localStorage.setItem('isLoggedin', 'true');
				return true;
			}			
			else {
				localStorage.removeItem('isLoggedin');
				this.router.navigate(['/login']);
				return false;
			}
		}
	}

	isAdminLoggedIn() {
		if (this.user && this.user.isAdmin == '1') {
			return true;
		}
		return false;
	}

	trns(text) {
		return this.translateService.instant(text);
	}

	displayCheckbox(d, r, p) {
		if (p) { return d == "1" ? this.trns('app.yes') : this.trns('app.no') };
		return `<i class="fa fa-${d == "1" ? 'check-' : ''}square"></i>`; 
	}

	waitUserReady(func) {
		if (this.user) {
			return func();
		}
		setTimeout(()=>{
			this.waitUserReady(func);
		}, 100);
	}

	waitUserpromise() {
		return new Promise((resolve, reject) => {
			this.waitUserReady(resolve);
		});
	}

	loadSettings() {
		const defalutSettings = this.settings;
		const res = localStorage.getItem('data_recipe');

		if (res === undefined || res === null || res === 'null') {
			this.saveSettings();
		}
		else {
			this.settings = JSON.parse(res);
		}
		//migrate defaults in
		for (let i in defalutSettings) {
			if (typeof this.settings[i] == "undefined") {
				this.settings[i] = defalutSettings[i];
				this.saveSettings();
			}
		}
	}

	request(route, parmsArray:any = {}) {
		var self = this;
		let parms = {... parmsArray};
		let headers = new HttpHeaders();
		if (this.settings.loginData) {
			headers = headers.set('Authorization', 'Bearer '+this.settings.loginData);
		}
		//parms.currentLang = this.translateService.currentLang || 'en';
		return new Promise(function(resolve, reject){///+"&t="+Math.random()
			self.HttpClient.post(self.apiUrl+route, parms, {responseType: 'json', headers: headers}).subscribe(async (d:any) => {
				if (d.error && d.error == "needLogin") {
					return this.router.navigate(['/login']);
				}
				resolve(d);
			});
		});
	}

	public upload(data) {
		//console.log('data',data);

		//add default data
		let headers = new HttpHeaders();
		if (this.settings.loginData) {
			headers = headers.set('Authorization', 'Bearer '+this.settings.loginData);
		}
		//data.append('currentLang', this.translateService.currentLang || 'en');

		return this.HttpClient.post<any>(this.apiUrl + "file/upload", data, {
			reportProgress: true,
			headers: headers,
			observe: 'events'
		}).pipe(map((event) => {
			switch (event.type) {
				case HttpEventType.UploadProgress:
					const progress = Math.round(100 * event.loaded / event.total);
					//console.log('progress',progress);
					return { status: 'progress', message: progress };
				case HttpEventType.Response:
					return event.body;
				default:
					return `Unhandled event: ${event.type}`;
			}
		}));
	}	

	async logout() {
		await this.request("user/logout", {});
		this.settings = {};
		this.user = false;
		this.saveSettings();
		localStorage.removeItem('isLoggedin');
	}

	handleMessageDefault(resp, func) {
		console.log('handleMessageDefault',resp);
	}
	
	display(title, text) {
		const modalRef = this.modalService.open(this.mm.getModal(), {centered: true, windowClass: 'documentDisplay'});
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.text = text;
		setTimeout(()=>{
			let ae: any = document.activeElement;
			ae.blur();
		}, 100)
	}

	alert(title, text) {
		const modalRef = this.modalService.open(this.mm.getModal(), {centered: true});
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.text = text;
		setTimeout(()=>{
			let ae: any = document.activeElement;
			ae.blur();
		}, 100)
	}

	confirm(title, text, doneFunc, doubleConfirm:boolean=false) {
		const modalRef = this.modalService.open(this.mm.getModal(), {centered: true});
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.text = text;
		modalRef.componentInstance.isConfirm = true;
		modalRef.componentInstance.isDoubleConfirm = doubleConfirm;
		modalRef.result.then((res)=>{
			if (res == "ok") {
				doneFunc();
			}
		})
		setTimeout(()=>{
			let ae: any = document.activeElement;
			ae.blur();
		}, 100)
	}

	extnull(value) {
		if (value<10) {
			return `0${value}`
		}
		return value;
	}

	openCustomModal(modalItem:NgbModalRef|any, settings:NgbModalOptions = {centered: true}):NgbModalRef {
		const modalRef = this.modalService.open(modalItem, settings);
		setTimeout(()=>{
			let ae: any = document.activeElement;
			ae.blur();
		}, 100);
		return modalRef;
	}

	getDates(data:any, dateFields) {
		for (let field of dateFields) {
			if (!data[field]) {
				data[field] = {year: new Date().getFullYear()-5, month: 1, day: 1};
				continue;
			}
			let date = new Date(data[field]);
			data[field] = {year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()};
		}
	}

	dateTime() {
		return new Date().toJSON().slice(0, 19).replace('T', ' ');
	}

	date() {
		return new Date().toJSON().slice(0, 10);
	}

	formatDate(date) {
		if (!date) {
			return "";
		}
		return moment(date).format("DD-MMM-YYYY")
	}

	public cachedHeight:any = {};
	calcMaxHeight(item) {
		if (this.cachedHeight[item]) {
			return this.cachedHeight[item];
		}
		let node:any = document.getElementById(item+"_rs");
		if (!node) {
			return 'initial';
		}
		let height:any = ((<any>window).innerHeight - node.offsetTop - 140) +"px";
		this.cachedHeight[item] = height;
		return height;
	}

	async loginAsCompany(id, statisticId = null) {
		let resp:any = await this.request('user/getTokenForTmpLoginCompany', {id: id, statisticId: statisticId});

		if (resp.ok) {
			localStorage.tokenBackup = this.settings.loginData;
			localStorage.currentDirectoryId = "0";
			this.settings.loginData = resp.token;
			this.user = resp.user;
			this.saveSettings();
			localStorage.setItem('isLoggedin', 'true');
			this.router.navigate(['/']);
			return;
		}
		else {
			this.alert(this.trns('app.error'), this.trns('app.log-in-failed-no-user-found'));
		}
	}
	
}
