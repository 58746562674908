import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmDateRoutingModule } from './sm-date-routing.module';
import { SmDateComponent } from './sm-date.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [SmDateComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule,


    CommonModule,
    SmDateRoutingModule
	],
	exports: [
		SmDateComponent
	]
})
export class SmDateModule { }
