import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../api.service';
import { FormBuilder, FormGroup } from  '@angular/forms';

@Component({
  selector: 'sm-image',
  templateUrl: './sm-image.component.html',
  styleUrls: ['./sm-image.component.scss']
})
export class SmImageComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() disabled: any = false;
	uploadResponse:any = {}

  constructor(public api: ApiService, private formBuilder: FormBuilder) {

	}

  ngOnInit() {
		
	}
	
	fileSelected($event) {
		if (!$event.target.files || !$event.target.files[0]) {
			return;
		}
		let form = this.formBuilder.group({
      file: $event.target.files[0]
		});
		const formData = new FormData();
		formData.append('file', form.get('file').value);

		this.api.upload(formData).subscribe(
			(res) => {
				this.uploadResponse = res;
				if (this.uploadResponse.status == "done") {
					console.log('this.uploadResponse',this.uploadResponse);
					this.model[this.field] = this.uploadResponse.slug;
					this.uploadResponse = {};
				}
			},
			(err) => {
				console.log('UPLOAD ERROR', err);
			}
		);
		console.log('E',$event.target.files[0]);
	}

	delete() {
		console.log('',this.model[this.field]);
		this.model[this.field] = "";
		return false;
	}

}