import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmTextareaRoutingModule } from './sm-textarea-routing.module';
import { SmTextareaComponent } from './sm-textarea.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [SmTextareaComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule,


    CommonModule,
    SmTextareaRoutingModule
	],
	exports: [
		SmTextareaComponent
	]
})
export class SmTextareaModule { }
