<div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>

	<!-- <select multiple class="form-control" [name]="field" (change)="update()" [(ngModel)]="selectedItems" [class.is-invalid]="error[field]">
		<option *ngFor="let option of options;let index = index;" [value]="option.value">{{option.label}}</option>
	</select> -->

	<ng-multiselect-dropdown-angular7
		[placeholder]="label | translate"
		[settings]="dropdownSettings"
		[data]="options"
		[(ngModel)]="selectedItems"
		(onSelect)="update()"
		(onSelectAll)="update()"
		(onDeSelect)="update()"
		(onDropDownClose)="update()"
		class="{{error[field] ? 'form-control' : ''}}"
		[class.is-invalid]="error[field]"
	>
	</ng-multiselect-dropdown-angular7>


	<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
</div>

