import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmRadioRoutingModule } from './sm-radio-routing.module';
import { SmRadioComponent } from './sm-radio.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [SmRadioComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule,


    CommonModule,
    SmRadioRoutingModule
	],
	exports: [
		SmRadioComponent
	]
})
export class SmRadioModule { }
