import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare type onChangeCallback = (value: Event) => void;

@Component({
  selector: 'sm-input',
  templateUrl: './sm-input.component.html',
	styleUrls: ['./sm-input.component.scss'],
})
export class SmInputComponent implements OnInit {
	public onChange: onChangeCallback = (value: Event) => {};

	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() disabled: any = false;
	@Output() onchange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
	}
	
	dataChanged($event): void {
    this.onchange.emit($event);
  }

}
