import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';

@Injectable()
export class AdminGuard implements CanActivate {
	constructor(private router: Router, private api: ApiService) { }

	async canActivate() {
		await this.api.waitUserpromise();

		if (!localStorage.getItem('isLoggedin') || !this.api.user || this.api.user.isAdmin != "1") {
			this.router.navigate(['/login']);
			return false;
		}

		return true;
	}
}
