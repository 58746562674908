import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sm-textarea',
  templateUrl: './sm-textarea.component.html',
  styleUrls: ['./sm-textarea.component.scss']
})
export class SmTextareaComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() disabled: any = false;
  constructor() { }

  ngOnInit() {
  }

}
