import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../../api.service';

@Component({
	selector: 'sm-select',
	templateUrl: './sm-select.component.html',
	styleUrls: ['./sm-select.component.scss']
})
export class SmSelectComponent implements OnInit {

	@Input() label: string;
	@Input() model: any;
	@Input() disabled: any = false;
	@Input() field: any;
	@Input() error: any = {};
	@Input() options: any = [];
	@Output() change = new EventEmitter();

	selectedItems: Array<any> = []
	dropdownList = [];
	dropdownSettings: any = {};

	constructor(private api:ApiService) { }

	ngOnChanges(changes: SimpleChanges) {
		this.updateSelect();
	}

	ngOnInit() {

		this.dropdownSettings = {
			singleSelection: true,
			idField: 'value',
			textField: 'label',
			selectAllText: this.api.trns('sm-select.select-all'),
			unSelectAllText: this.api.trns('sm-select.unselect-all'),
			itemsShowLimit: 20,
			closeDropDownOnSelection: true,
			allowSearchFilter: true
		};
	}

	updateSelect() {
		if (!this.options) {
			this.options = [];
		}
		this.selectedItems = [];
		let splitted: Array<string> = this.model[this.field] ? this.model[this.field].split(",") : [];
		for (let option of this.options) {
			if (splitted.indexOf(option.value) != -1) {
				this.selectedItems.push(option);
			}
		}
	}

	update() {
		let prev = this.model[this.field];
		this.model[this.field] = this.selectedItems.map(item => typeof item.value != 'undefined' ? item.value : item).join(",");
		if (this.model[this.field] != prev) {
			this.runChange();
		}
	}

	runChange() {
		this.change.emit(this.model[this.field]);
	}
}
