<div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>

	<div class="{{error[field] ? 'form-control' : ''}}" [class.is-invalid]="error[field]">
		<label *ngFor="let option of options;let index = index;">
			<input type="radio" [disabled]="disabled" [(ngModel)]="model[field]" [name]="field+index" [value]="option.value" /> {{option.label}}
		</label>
	</div>

	<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
</div>