import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmImageRoutingModule } from './sm-image-routing.module';
import { SmImageComponent } from './sm-image.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [SmImageComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule,


    CommonModule,
    SmImageRoutingModule
	],
	exports: [
		SmImageComponent
	]
})
export class SmImageModule { }
