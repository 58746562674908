<div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>
	<div class="input-group datepicker-input">
		<input class="form-control" (dateSelect)="setNewDate()" (click)="d.toggle()" placeholder="yyyy-mm-dd" [name]="field" [(ngModel)]="dateContainer" [class.is-invalid]="error[field]" readonly ngbDatepicker #d="ngbDatepicker">
		<button class="input-group-addon" (click)="d.toggle()" type="button">
			<span class="fa fa-calendar"></span>
		</button>
		<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
	</div>
</div>
