<div class="modal-header">
	<h4 class="modal-title">{{title}}</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.close('Schliessen')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<p [innerHTML]="text"></p>
</div>
<div class="modal-footer">
	<div *ngIf="isDoubleConfirm" class="form-group">
		<label class="form-control-label" for="doubleconfirm">{{'Geben Sie hier das Wort "LÖSCHEN" ein:' | translate}}</label>
		<input [(ngModel)]="doubleConfirmText" name="doubleconfirm" type="text" class="form-control"/>
	</div>
	<button *ngIf="!isConfirm" type="button" class="btn btn-outline-dark" (click)="activeModal.close('Schliessen')">{{'modal.close' | translate}}</button>
	<button *ngIf="isConfirm" type="button" class="btn btn-danger" (click)="activeModal.close('Schliessen')">{{'modal.cancel' | translate}}</button>
	<button *ngIf="isConfirm" [disabled]="checkDoubleConfirmDisabled()" type="button" class="btn btn btn-success" (click)="activeModal.close('ok')">{{'modal.ok' | translate}}</button>
</div>
