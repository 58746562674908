import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-modal-content',
	templateUrl: './modal.html',
	styleUrls: ['./modal.component.scss'],
})
export class NgbdModalContent implements OnInit {
	@Input() title:string = "";
	@Input() text:string = "";
	@Input() isConfirm: boolean = false;
	@Input() isDoubleConfirm: boolean = false;
	public doubleConfirmText:string = "";

  constructor(public activeModal: NgbActiveModal)  {

	}

	ngOnInit() {

	}

	checkDoubleConfirmDisabled() {
		if (!this.isDoubleConfirm) {
			return false;
		}
		if (this.doubleConfirmText.toLowerCase() != "löschen") {
			return true;
		}
		return false;
	}
}
