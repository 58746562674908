<div class="form-group">
	<label *ngIf="label" class="form-control-label" [for]="field">{{label | translate}}</label>

	<ng-multiselect-dropdown-angular7
		[placeholder]="(label||'' | translate) + '...'"
		[settings]="dropdownSettings"
		[data]="options"
		[disabled]="disabled"
		[(ngModel)]="selectedItems"
		(onDropDownClose)="update()"
		class="{{error[field] ? 'form-control' : ''}}"
		[class.is-invalid]="error[field]"
	>
	</ng-multiselect-dropdown-angular7>

	<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
</div>
