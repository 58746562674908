<div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>
	<input [(ngModel)]="model[field]" [disabled]="disabled" [name]="field" type="text" class="form-control" [class.is-invalid]="error[field]" placeholder="{{label | translate}}">
	<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
</div>
<div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}{{'sm-input-password.confirm' | translate}}</label>
	<input [(ngModel)]="model[field+2]" [disabled]="disabled" [name]="field" type="text" class="form-control" [class.is-invalid]="error[field+2]" placeholder="{{label | translate}}{{'(Confirm)' | translate}}">
	<div *ngIf="error[field+2]" class="invalid-feedback">{{error[field]}}</div>
</div>

