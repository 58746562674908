import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule, NgbAlertModule, NgbPaginationModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard, AdminGuard } from './shared';
import { ModalModule } from './modal/modal.module';
import { DragulaModule } from 'ng2-dragula';

import localeDe from '@angular/common/locales/de-CH';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeDe, 'de-CH');

import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';


import {
	SmCheckboxMultipleModule,
	SmCheckboxModule,
	SmDateModule,
	SmFileModule,
	SmHiddenModule,
	SmHtmlModule,
	SmFileMultipleModule,
	SmImageModule,
	SmImageMultipleModule,
	SmInputNumericModule,
	SmInputPasswordModule,
	SmRadioModule,
	SmSelectModule,
	SmSelectMultipleModule,
	SmTextareaModule,
	SmDatetimeModule,
	SmInputModule
} from './shared/inputs';

@NgModule({
	imports: [
		JwBootstrapSwitchNg2Module,
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		LanguageTranslationModule,
		FormsModule,
		NgbPaginationModule,
		NgbModule,
		NgbAlertModule,
		NgbTabsetModule,
		NgMultiSelectDropDownModule,
		DragulaModule.forRoot(),
		ReactiveFormsModule,
		AppRoutingModule,
		ModalModule,
		SmCheckboxMultipleModule,
		SmCheckboxModule,
		SmDateModule,
		SmFileModule,
		SmHiddenModule,
		SmHtmlModule,
		SmFileMultipleModule,
		SmImageModule,
		SmImageMultipleModule,
		SmInputNumericModule,
		SmInputPasswordModule,
		SmRadioModule,
		SmSelectModule,
		SmSelectMultipleModule,
		SmTextareaModule,
		SmDatetimeModule,
		SmInputModule
	],
	declarations: [AppComponent],
	providers: [AuthGuard, AdminGuard],
	bootstrap: [AppComponent],
	exports: []
})
export class AppModule { }
