<div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>

	<div class="{{error[field] && model[field] ? 'form-control' : ''}}" [class.is-invalid]="error[field]">
		<ngb-progressbar *ngIf="uploadResponse.status == 'progress'" showValue="true" type="info" [value]="uploadResponse.message"></ngb-progressbar>
		<input *ngIf="!model[field] && uploadResponse.status != 'progress'" [disabled]="disabled" (change)="fileSelected($event)" [name]="field" type="file" class="form-control" [class.is-invalid]="error[field]"
			placeholder="{{label | translate}}">
		<div *ngIf="model[field] && uploadResponse.status != 'progress'">
			<a [href]="api.fileUrl+model[field]" target="_blank">{{model[field]}}</a> &nbsp; <button (click)="delete()" class="btn btn-primary"><i class="fa fa-trash"></i>
				{{'sm-file.loschen' | translate}}</button>
		</div>
	</div>

	<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
</div>