import {Component, OnInit, Input, SimpleChanges, Injectable} from '@angular/core';
import { formatDate } from '@angular/common';
import {NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
  en: {
    weekdays: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    months: ["Jan", "Feb", "März", "Apr", "Mai", "Juni", "Juli", "Aug", "Sep", "Okt", "Nov", "Dez"],
  },
};

// Define a service holding the language. You probably already have one if your app is i18ned.
@Injectable()
export class I18n {
  language = 'en';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
	}
	
	getDayAriaLabel(date) {
		const jsDate = new Date(date.year, date.month - 1, date.day);
		return formatDate(jsDate, 'fullDate', this._i18n.language);
	}

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
}


@Component({
  selector: 'sm-date',
  templateUrl: './sm-date.component.html',
  styleUrls: ['./sm-date.component.scss'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}] // define custom NgbDatepickerI18n provider
})
export class SmDateComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() value:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() disabled: any = false;
	dateContainer:any = {};

  constructor() { 
		//this.initDate();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.value) {
			this.initDate();
		}
	}

	initDate() {
		let date = new Date();
		if (this.value) {
			date = new Date(this.value);
		}
		this.dateContainer = {year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()};
	}

  ngOnInit() {
		this.initDate();
	}
	
	setNewDate() {
		function addZeros(number) { 
			return (number < 10 ? '0' : '') + number;
		}		
		this.model[this.field] = this.dateContainer.year+"-"+addZeros(this.dateContainer.month)+"-"+addZeros(this.dateContainer.day);
	}

}
