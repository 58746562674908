import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sm-checkbox-multiple',
  templateUrl: './sm-checkbox-multiple.component.html',
  styleUrls: ['./sm-checkbox-multiple.component.scss']
})
export class SmCheckboxMultipleComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() options:any;
	@Input() disabled: any = false;

	constructor() { }
	
	ngOnChanges(changes: SimpleChanges) {
		if (changes.options) {
			this.ngOnInit();
		}
	}

  ngOnInit() {
		if (!this.options) {
			this.options = [];
		}
		let selectedOptions:any = this.model[this.field] ? this.model[this.field].split(",") : [];
		for (let i in this.options) {
			this.options[i].checked = selectedOptions.indexOf(this.options[i].value) != -1;
		}
	}
	
	update(option) {
		option.checked = !option.checked;
		let newSelection = [];
		for (let o of this.options) {
			if (o.checked) {
				newSelection.push(o.value);
			}
		}
		this.model[this.field] = newSelection.join(",");
	}

}
